document.addEventListener('alpine:init', () => {
  Alpine.data('tabs', () => ({
    currentScroll: 0,
    maxScroll: 0,
    scrollWidth: 0,
    init() {
      this.$watch('maxScroll', (value) => {
        let wantedScroll = this.$refs.container.clientWidth;

        this.scrollWidth = 0;
        if (value > 0) {
          this.scrollWidth = wantedScroll > value ? value : wantedScroll;
        }
      });
      this.currentScroll = this.$refs.scrollable.scrollLeft;
      this.maxScroll = this.$refs.scrollable.offsetWidth - this.$refs.container.offsetWidth;
    },
    calculateScroll(e) {
      this.currentScroll = e.target.scrollLeft;
    },
    calculateMaxScroll() {
      this.maxScroll = this.$refs.scrollable.offsetWidth - this.$refs.container.offsetWidth;
    },
    scrollLeft() {
      let wantedScroll = this.currentScroll - this.scrollWidth;
      if (wantedScroll > 0) {
        wantedScroll += this.$refs.arrowLeft.clientWidth;
      }
      this.$refs.container.scroll({
        left: wantedScroll,
        behavior: 'smooth'
      });
    },
    scrollRight() {
      let wantedScroll = this.currentScroll + this.scrollWidth;
      if (wantedScroll < this.maxScroll) {
        wantedScroll -= this.$refs.arrowRight.offsetWidth;
      }
      this.$refs.container.scrollTo({
        left: wantedScroll,
        behavior: 'smooth'
      });
    }
  }));

  Alpine.directive('tooltip', (el, { expression }, { effect, cleanup }) => {
    const createTooltip = () => {
      $(el).tooltip('dispose');
      const placement = el.getAttribute('x-tooltip-placement') ?? 'auto';
      let title = expression;
      if (title === '') {
        title = el.getAttribute('data-title');
      }
      $(el).tooltip({
        title,
        placement,
        html: el.hasAttribute('x-tooltip-html'),
        boundary: el.getAttribute('x-tooltip-boundary') ?? 'window'
      });
    };
    effect(createTooltip);
    cleanup(() => $(el).tooltip('dispose'));
  });

  Alpine.magic('tooltip', (el, { cleanup }) => {
    return (title = '', placement = 'top') => {
      let disposed = false;
      if (title === '') {
        title = el.getAttribute('data-title');
      }

      $(el).tooltip({ title, placement });

      cleanup(() => {
        if (disposed) {
          return;
        }
        disposed = true;
        $(el).tooltip('dispose');
      });

      return () => {
        disposed = true;
        $(el).tooltip('dispose');
      };
    };
  });
});
